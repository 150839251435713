import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
//异步
const Hesuan =()=>import('../views/Hesuan/index.vue')
const Area =()=>import('../views/Area/index.vue')
const Prevention =()=>import('../views/Prevention/index.vue')
const Citys = ()=>import('../views/Citys/Citys.vue')
const Travel = ()=>import('../views/Travel/Travel.vue')
const SelectCity = ()=>import('../views/SelectCity/SelectCity.vue')

// sakhr 最新路由 职通宝app用户注册登录
const Into1 = ()=>import('../views/Into1/into1.vue')  // 微信，支付宝付款总入口 旧版*************
const PayAll = ()=>import('../views/Into1/payAll.vue')  // 微信，支付宝付款总入口 最新***************
const PayAllResult = ()=>import('../views/Into1/payAllResult.vue')  // 微信，支付宝付款付款成功前端回调页 最新***************
const Configuration = ()=>import('../views/Into1/testPay.vue')  //零时测试用
const AliPay = ()=>import('../views/Into1/aLiPay.vue')  //支付宝支付入口检测
const WxPay = ()=>import('../views/Into1/wxPay.vue')  //支付宝支付入口检测
const WxPay2 = ()=>import('../views/Into1/wxPay2.vue')  //支付宝支付入口检测2
const WxPay3 = ()=>import('../views/Into1/wxPay3.vue')  //支付宝支付入口检测3
const WxPayOutRedir = ()=>import('../views/Into1/wxPayOutRedir.vue')  //微信外支付成功后跳转网址
// const AliPayIndex = ()=>import('../views/Into1/aliPayIndex.vue')  //支付宝支付回调跳转
const Ztbwachali = ()=>import('../views/ZtbWactOrAli/ztbwachali.vue') //微信支付宝和其他浏览器区分跳转页面

const PayAllSecond = ()=>import('../views/Into1/payAllSecond.vue') //二套支付1 低压电工支付页面
const PayAllSecond2 = ()=>import('../views/Into1/payAllSecond2.vue') //二套支付2 焊工，高处作业等其它支付页面

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:'/hesuan',
    name:'Hesuan',
    component:Hesuan
  },
  {
    path:'/area',
    name:'Area',
    component:Area
  },{
    path:'/prevention',
    name:'Prevention',
    component:Prevention
  },
  {
    path:'/citys/:cityname',
    name:'Citys',
    component:Citys,
    props:true,
  },
  {
    path:'/travel',
    name:'Travel',
    component:Travel
  },{
    path:'/selectCity',
    name:'SelectCity',
    component:SelectCity
  },
  // 微信，支付宝付款总入口 旧版*************
  {
    path:'/into1',
    name:'Into1',
    component:Into1
  },
  // test运营页
  {
    path:'/configuration',
    name:'Configuration',
    component:Configuration
  },
  {
    path:'/ztbwachali',
    name:'Ztbwachali',
    component:Ztbwachali
  },
  // 微信，支付宝付款总入口 最新***************
  {
    path:'/payAll',
    name:'PayAll',
    component:PayAll
  },
  // 付款成功返回页 最新***************
  {
    path:'/payAllResult',
    name:'PayAllResult',
    component:PayAllResult
  },
  // 支付宝付款跳转页
  {
    path:'/aliPay',
    name:'AliPay',
    component:AliPay
  },
  // 微信付款跳转页
  {
    path:'/wxPay',
    name:'WxPay',
    component:WxPay
  },
  // 微信付款跳转页2
  {
    path:'/wxPay2',
    name:'WxPay2',
    component:WxPay2
  },
  {
    path:'/wxPay3',
    name:'WxPay3',
    component:WxPay3
  },
  // {
  //   path:'/mpVerify',
  //   name:'MpVerify',
  //   component:MpVerify
  // }
    // 微信外支付成功后跳转网址
    {
      path:'/wxPayOutRedir',
      name:'WxPayOutRedir',
      component:WxPayOutRedir
    },
  // *******二套支付********
  {
    path:'/payAllSecond',
    name:'payAllSecond',
    component:PayAllSecond
  },

  {
    path:'/payAllSecond2',
    name:'payAllSecond2',
    component:PayAllSecond2
  },
]

const router = new VueRouter({
  routes
})

export default router
