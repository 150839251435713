/**
 * 公共的请求的接口
 * 
 */
const base ={
    host:'http://api.tianapi.com',//天行数据 --疫情病毒
    baseUrl:'https://yupn.api.storeapi.net',//挖数据---国内疫情
    covInfo:'/ncov/index?key=b5919dd6f573907e378d0a6be7a78ff3',//疫情病毒信息
    chinaData:'/api/94/219?format=json&appid=6938&sign=687569b1fd450f3491cd6e72f82aacf7',//国内疫情接口
    worldData:'/api/94/220?format=json&appid=6938&sign=687569b1fd450f3491cd6e72f82aacf7',//世界疫情数据
    city:'/api/94/221',//城市接口
    //format=json&appid=6938&city_name=城市名称&sign=md5加密字符串 
    allData:'/api/94/222?format=json&appid=6938&sign=687569b1fd450f3491cd6e72f82aacf7',//全球接口
    swiperBanner:'http://iwenwiki.com/wapicovid19/ncovimg.php',//轮播图疫情
    // travel:'http://apis.juhe.cn/springTravel/citys?key=171e165a7d991c5f6ecd5194c54778ef',
    travel:'/api/springTravel/citys?key=171e165a7d991c5f6ecd5194c54778ef',
    travelQuery:'/api/springTravel/query?key=171e165a7d991c5f6ecd5194c54778ef',//城市疫情&from=10191&to=10349
    //360接口地址：https://bang.360.cn/aj/getcitycode 
    getcity:'/foo/aj/getcitycode',


    // =======================================================技能家 start===============================================================
    //注意：换服的时候同时修改wxPay2 wondow.location  http://jn.evoka.com.cn  http://jnj.evoka.com.cn
    // 客户端进入支付页面全地址  http://jnj.evoka.com.cn/#/configuration?user_telephone=13524181542&app_id=2
    // ztbHost: 'http://127.0.0.1:8000', //本地接口


    // ztbHost: 'http://a7bb4b9.cpolar.cn', //本地接口隧道
    // WindowLocation: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://jn.evoka.com.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome', // 测试服（本地），请求微信传递code给后台
    // AppLocationUrl115: 'http://jn.evoka.com.cn/#/payAllSecond?jg=技能家机构', //app端进入运营H5页地址，测试服（本地） -- v1.1.5
    // AppLocationUrl150_2: 'http://jn.evoka.com.cn/#/payAllSecond2?jg=技能家机构', //app端进入运营H5页地址，测试服（本地） -- v1.5.0
    // AppLocationUrl: 'http://jn.evoka.com.cn/#/payAll?jg=技能家机构', //app端进入运营H5页地址，测试服（本地）-- v1.1.3

    ztbHost: 'http://47.105.158.91:8101',//正式服接口
    WindowLocation: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://jnj.evoka.com.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome', // 正式服，请求微信传递code给后台
    AppLocationUrl115: 'http://jnj.evoka.com.cn/#/payAllSecond?jg=技能家机构', //app端进入运营H5页地址，正式服（线上） -- v1.1.5
    AppLocationUrl150_2: 'http://jnj.evoka.com.cn/#/payAllSecond2?jg=技能家机构', //app端进入运营H5页地址，测试服（本地） -- v1.5.0
    AppLocationUrl: 'http://jnj.evoka.com.cn/#/payAll?jg=技能家机构', //app端进入运营H5页地址，正式服 -- v1.1.3



    // ztbWebHost: 'http://www.evoka.com.cn/jnj_download.html', //旧版 微信内支付后返回的app下载地址 例如：http://jn.evoka.com.cn/#/payAllResult 
    ztbWebHost: 'http://www.evoka.com.cn/jnj_download_new.html', //新版 微信内支付后返回的app下载地址 例如：http://jn.evoka.com.cn/#/payAllResult
    ztbBindPhone: '/api/ztb_bind_phone/', //H5页面 用户名，电话号码绑定
    h5Pay: '/api/wx_pay/', //H5微信外 支付
    h5Pay2: '/api/wx_pay2/', //H5微信内 支付2 订单创建
    h5Pay3: '/api/wx_pay3/', 
    wxCode: '/api/wx_code/', //H5微信支付获取code
    wxPayInOrderCreate: '/api/wx_pay_in_order_create/', //H5微信内支付订单自有平台创建
    wxInOrderUpdate: '/api/wx_in_order_update/', //H5微信内，外支付订单更新
    AliPay: '/alipay/index/', //支付宝支付1
    AliPay3: '/alipay/index3/', //支付宝支付3
    AliPayResult: '/alipay/result/', //支付成功返回展示
    postUserPhoneAndPrice: '/api/post_user_phone_price/' //价格合成
    // =======================================================技能家 end==================================================================
}

export default base;
