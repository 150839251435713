<template>
  <transition name="dialog-fade">
    <!--self：事件修饰符，只有点击自己才触发，点击子元素不触发  -->
    <div class="cat-dialog__wrapper" v-show="visible" @click.self="handleClose">
      <!-- 对话框 -->
      <div class="cat-dialog" :style="{ width, marginTop: top }">
 
        <!-- 对话框顶部 标题 + 关闭按钮 -->
        <div class="cat-dialog__header">
          <slot name="title">
            <span class="cat-dialog__title">{{ title }}</span>
          </slot>
          <button class="cat-dialog__headerbtn" @click="handleClose">
            <i class="cat-icon-close"></i>
          </button>
        </div>
 
        <!-- 对话框内容 -->
        <div class="cat-dialog__body">
          <slot></slot>
        </div>
 
        <!-- 对话框底部 一般都是一些操作，没有传入footer插槽就不显示v-if -->
        <div class="cat-dialog__footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
 
<script>
export default {
  name: "CatDialog",
  props: {
    title: {
      type: String,
      default: "提示",
    },
    // 弹框宽度
    width: {
      type: String,
      default: "30%",
    },
    // 弹框距离顶部距离
    top: {
      type: String,
      default: "15vh",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      //visible是父组件传过来的props，子组件不能直接修改，需要子传父
      this.$emit("update:visible", false);
    },
  },
};
</script>

<!-- <style lang="scss">
// 进入动画
.dialog-fade-enter-active {
  animation: dialog-fade-in 0.4s;
}
// 离开动画
.dialog-fade-leave-active {
  animation: dialog-fade-out 0.4s;
}
 
@keyframes dialog-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
 
@keyframes dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style> -->