import Vue from 'vue';
import { Tab, Tabs } from 'vant';
import { Cascader } from 'vant';
import { Field } from 'vant';
import { Popup } from 'vant';
import { Button } from 'vant';
import { IndexBar, IndexAnchor } from 'vant';
import { Cell } from 'vant';
import { Col, Row } from 'vant';
import { Collapse, CollapseItem } from 'vant';
// import { Dialog } from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Cell);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Cascader);
Vue.use(Tab);
Vue.use(Tabs);
// Vue.use(Dialog);