import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入css初始化
import './assets/css/base.css'
//引入js适配移动端
// import './assets/js/phone'
//引入vant库
import './plugins/vant'
//挂载原型上 
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts;
import 'babel-polyfill'
import CatDialog from "./components/Dialog.vue";
Vue.component(CatDialog.name, CatDialog);
import { Icon, Toast, Swipe, SwipeItem, Tag, Divider, RadioGroup, Radio, Button, Col, Row, Grid, GridItem } from 'vant';
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Grid);
Vue.use(GridItem);
//使用开发echarts插件 
// import echarts from './plugins/echarts'
// Vue.use(echarts)
//引入swiper
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)
//中央系统
// import Bus from './utils/event-bus.js'
// Vue.prototype.$bus = Bus;

Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App)
}).$mount('#app')
